/* eslint-disable max-len */
export const translationsRu = {
  header: {
    about: 'О студии',
    contacts: 'Контакты',
    gallery: 'Галерея',
    location: 'Как добраться',
    pricing: 'Цены',
    schedule: 'Расписание',
    name: 'Студия танцев',
  },
  languages: {
    english: 'Английский',
    russian: 'Русский',
    spanish: 'Испанский',
  },
  socials: {
    contactUs: 'Записаться',
    email: 'Электронная\u00A0почта',
    phone: 'Позвонить',
    telegram: 'Telegram',
    whatsApp: 'WhatsApp',
  },
  about: {
    p1st: 'Танец — это не только движения тела под музыку. Начать танцевать нелегко, но танец дарит возможность почувствовать свободу, страсть, вдохновение.',
    p2nd: 'В\u2004Marca\u2004El\u2004Paso мы поможем вам прожить этот опыт, чтобы вы могли преодолеть свои страхи, зажимы и блоки. И почувствовали себя более гибкими, свободными и счастливыми.',
    p3rd: 'В\u2004Marca\u2004El\u2004Paso важно танцевать душой и сердцем. Marca\u2004El\u2004Paso\u00A0—\u00A0это\u00A0атмосфера, которой можно поделиться с семьей и научиться новым культурам.',
    p4th: 'Приходите и\u00A0учитесь\u00A0с\u00A0нами!',
  },
  weekdays: {
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
  },
  schedule: {
    subject: 'Занятие',
    teacher: 'Преподаватель',
    teachers: 'Преподаватели',
    time: 'Время',
  },
  prices: {
    singleVisit: 'Разовое\u00A0посещение',
    subscriptions: 'Абонементы',
    month: '{{count}}\u00A0мес',
    amountCount: '{{count}} уроков',
    amountCount_one: '{{count}} урок',
    amountCount_few: '{{count}} урока',
    amountCount_many: '{{count}} уроков',
    amountCount_other: '{{count}} урока',
    amount: 'Количество занятий',
    validity: 'Срок действия',
    price: 'Стоимость',
    trial: 'Пробное\u00A0*',
    regular: 'Обычное',
    trialDetailed: '* Ваше первое пробное занятие в студии',
    personalTitle: 'Персональные занятия',
    personalText: 'Стоимость, даты и сроки индивидуальных занятий обговариваются с преподавателем',
  },
  location: {
    resetMap: 'Сбросить карту',
    address: 'Адрес:',
    addressGlobal: '450075, Уфа, Октябрьский район,',
    addressLocal: 'ул.\u00A0Рихарда\u00A0Зорге, 75, -1\u00A0этаж',
    details1st: 'Здание «Zorge\u00A0Fitness», вход с обратной стороны здания.',
    details2nd: 'Ориентир\u00A0—\u00A0вывеска «ООО\u00A0Содержание»',
    videoDetails: 'Подробнее\u00A0—\u00A0на\u00A0видео',
    addressToCopy: 'Уфа, ул. Рихарда Зорге, 75',
    copied: 'Скопировано',
    copyError: 'Ошибка копирования',
    getDirections: 'Проложить маршрут',
  },
  footer: {
    madeWithLove: 'Сделал с',
    devName: 'Роман\u00A0Попов',
    notAnOffer: 'Информация, размещенная на сайте не является публичной офертой',
  },
  serverInteraction: {
    loadError: 'Ошибка загрузки данных\nПожалуйста, попробуйте позднее',
  },
};
