/* eslint-disable max-len */
export const translationsEn = {
  header: {
    about: 'About',
    contacts: 'Contacts',
    gallery: 'Gallery',
    location: 'Location',
    pricing: 'Pricing',
    schedule: 'Schedule',
    name: 'Dance studio',
  },
  languages: {
    english: 'English',
    russian: 'Russian',
    spanish: 'Spanish',
  },
  socials: {
    contactUs: 'Contact us',
    email: 'Email',
    phone: 'Call',
    telegram: 'Telegram',
    whatsApp: 'WhatsApp',
  },
  about: {
    p1st: 'Dancing is not just body movements to music. It is not easy to start dancing, but dancing gives the opportunity to feel freedom, passion, inspiration.',
    p2nd: 'At\u2004Marca\u2004El\u2004Paso we will help you live this experience so that you can overcome your fears, inhibitions and blocks. And feel more flexible, free and happy.',
    p3rd: 'At\u2004Marca\u2004El\u2004Paso it is important to dance with your soul and heart. Marca\u00A0el\u00A0Paso\u00A0is an\u00A0atmosphere to share with family and to learn new cultures.',
    p4th: 'Come\u00A0and\u00A0learn with\u00A0us!',
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  schedule: {
    subject: 'Subject',
    teacher: 'Teacher',
    teachers: 'Teachers',
    time: 'Time',
  },
  prices: {
    singleVisit: 'Single\u00A0visit',
    subscriptions: 'Subscriptions',
    month: '{{count}}\u00A0mos',
    month_one: '{{count}}\u00A0mo',
    month_other: '{{count}}\u00A0mos',
    amountCount: '{{count}} lessons',
    amountCount_one: '{{count}} lesson',
    amountCount_other: '{{count}} lessons',
    amount: 'Lessons amount',
    validity: 'Validity period',
    price: 'Price',
    trial: 'Trial\u00A0*',
    regular: 'Regular',
    trialDetailed: '* Your first trial lesson at the studio',
    personalTitle: 'Personal lessons',
    personalText: 'The cost, dates and duration of individual lessons are discussed with the teacher',
  },
  location: {
    resetMap: 'Reset map',
    address: 'Address:',
    addressGlobal: '450075, Ufa, Republic of Bashkortostan,',
    addressLocal: 'Rikharda\u00A0Zorge Street, 75, -1st\u00A0floor',
    details1st: 'Zorge\u00A0Fitness, entrance from the back side of the building.',
    details2nd: 'Landmark\u00A0—\u00A0the sign\u00A0«ООО\u00A0Содержание»',
    videoDetails: 'More details\u00A0—\u00A0on the\u00A0video',
    addressToCopy: 'Ufa, Rikharda Zorge Street, 75',
    copied: 'Copied',
    copyError: 'Unable to copy',
    getDirections: 'Get directions',
  },
  footer: {
    madeWithLove: 'Made with',
    devName: 'Roman\u00A0Popov',
    notAnOffer: 'The information posted on the site is not a public offer',
  },
  serverInteraction: {
    loadError: 'Loading data error\nPlease try again later',
  },
};
