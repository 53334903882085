/* eslint-disable max-len */
export const translationsEs = {
  header: {
    about: 'Sobre nosotros',
    contacts: 'Contactos',
    gallery: 'Galería',
    location: 'Ubicación',
    pricing: 'Precios',
    schedule: 'Cronograma',
    name: 'Estudio de baile',
  },
  languages: {
    english: 'Inglés',
    russian: 'Ruso',
    spanish: 'Español',
  },
  socials: {
    contactUs: 'Contáctenos',
    email: 'Correo\u00A0electrónico',
    phone: 'Para\u00A0llamar',
    telegram: 'Telegram',
    whatsApp: 'WhatsApp',
  },
  about: {
    p1st: 'Bailar no es sólo mover el cuerpo al ritmo de la música. No es fácil empezar a bailar, pero bailar da la oportunidad de sentir libertad, pasión, inspiración.',
    p2nd: 'En\u2004Marca\u2004El\u2004Paso te ayudamos a vivir esta experiencia para que puedas superar tus miedos, inhibiciones y bloqueos. Y te sientas más flexible, libre y feliz',
    p3rd: 'En\u2004Marca\u2004El\u2004Paso es importante bailar con el alma y el corazón. Marca\u2004El\u2004Paso\u00A0es\u00A0un\u00A0ambiente para compartir en familia y conocer nuevas culturas.',
    p4th: '¡Ven\u00A0y\u00A0aprende con\u00A0nosotros!',
  },
  weekdays: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
  },
  schedule: {
    subject: 'Asignatura',
    teacher: 'Maestro',
    teachers: 'Maestros',
    time: 'Tiempo',
  },
  prices: {
    singleVisit: 'Visita\u00A0única',
    subscriptions: 'Suscripciones',
    month: '{{count}}\u00A0meses',
    month_one: '{{count}}\u00A0mes',
    month_other: '{{count}}\u00A0meses',
    amount: 'Cantidad de clases',
    amountCount: '{{count}} lección',
    amountCount_one: '{{count}} lección',
    amountCount_other: '{{count}} lecciones',
    validity: 'Periodo de validez',
    price: 'Precio',
    trial: 'De prueba\u00A0*',
    regular: 'Regular',
    trialDetailed: '* Tu primera lección de prueba en el estudio',
    personalTitle: 'Lecciones personales',
    personalText: 'El coste, las fechas y la duración de las clases individuales se negocian con el maestro',
  },
  location: {
    resetMap: 'Restablecer mapa',
    address: 'Dirección:',
    addressGlobal: '450075, Ufa, República de Bashkortostán,',
    addressLocal: 'Calle Rikharda\u00A0Zorge,\u00A075, -1\u00A0piso',
    details1st: 'Zorge\u00A0Fitness, entrada por la parte trasera del edificio.',
    details2nd: 'Punto de referencia\u00A0—\u00A0el cartel «ООО\u00A0Содержание»',
    videoDetails: 'Más detalles\u00A0—\u00A0en\u00A0el\u00A0vídeo',
    addressToCopy: 'Ufa, Calle Rikharda Zorge, 75',
    copied: 'Copiado',
    copyError: 'Error de copia',
    getDirections: 'Obtener direcciones',
  },
  footer: {
    madeWithLove: 'Hecho con',
    devName: 'Roman\u00A0Popov',
    notAnOffer: 'La información publicada en el sitio no constituye una oferta pública',
  },
  serverInteraction: {
    loadError: 'Error al cargar datos\nPor favor inténtalo de nuevo más tarde',
  },
};
